/* global
  $
*/

(function () {
  var scrollable = $('html, body')

  window._ = {
    range: function (fromValue, toValue) {
      var values = []

      fromValue = parseInt(fromValue, 10)
      toValue = parseInt(toValue, 10)

      var total = Math.abs(fromValue - toValue)
      var direction = fromValue > toValue ? -1 : 1

      for (var i = 0; i <= total; i++) {
        values.push(fromValue + (i * direction))
      }

      return values
    }
  }

  window.ViaReal = {
    scrollTo: function (position) {
      scrollable
        .stop(true, false)
        .animate({'scrollTop': position}, 400)
        .on('scroll mousedown DOMMouseScroll mousewheel keyup', function () {
          scrollable.stop()
        })
    },

    USAStates: [
      'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID',
      'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS',
      'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK',
      'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV',
      'WI', 'WY'
    ]
  }
}()); // eslint-disable-line
