/* global
  FastClick
*/

(function () {
  'use strict'

  var bodyStyle = document.body.style

  if (
    'backdropFilter' in bodyStyle ||
    'webkitBackdropFilter' in bodyStyle
  ) {
    $('html').addClass('backdropfilter')
  }

  FastClick.attach(document.body)
}())
