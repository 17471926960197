/* global
  $
*/

;(function () {
  'use strict'

  $(function () {
    var checkboxes = $('header nav :checkbox')

    checkboxes.on('change', function (e) {
      checkboxes.not($(e.target)).prop('checked', false)
    })
  })
}())
